<template>
  <body-layout>
    <div class="actions flex" slot="actions">
      <a-button type="danger" v-has="'material:delete'" @click="batchDel" class="mr-3" v-if="selectedRowKeys.length">删除</a-button>
      <a-button type="primary" v-has="'material:edit'" @click="openAddForm">新建</a-button>
      <!--  <a-button v-has="'ProductDetailPop:add'" type="primary" @click="openAddForm">新建</a-button> -->
    </div>
    <div class="table-page-search-wrapper" slot="search">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row type="flex" :gutter="16">
          <a-col>
            <a-input style="width:300px" v-model="queryParam.name" placeholder="请输入名称" />
          </a-col>
          <a-col>
            <a-button type="primary" icon="search" @click="searchQuery">搜索</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <v-table
      ref="newTable"
      :queryParamProp="queryParam"
      :selectedRowKeysprop="selectedRowKeys"
      :url="url"
      @openModal="openModal"
      :columns="columns"
      :isMaterial="true"
      @setRowkeys="setRowkeys"
      :isPreview="false"
    />
    <material-modal
      :open="drawerShow"
      :cur-row="curRow"
      :isForm="isNewForm"
      @closed="drawerClosed"
      :hide-edit-btn="false"
    >
    </material-modal>
  </body-layout>
</template>

<script>
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import materialModal from './modules/material-modal'
import { checkPermission } from '@/utils/hasPermission'
import vTable from '@/components/v-table/vTable'
import { deleteAction } from '../../api/manage'
export default {
  mixins: [JeecgListMixin],
  components: {
    vTable,
    materialModal
  },
  data() {
    return {
      columns: [
        {
          title: '序号',
          dataIndex: 'materialOrder',
          width: 100,
          checked: true,
          isNum: false
        },
        {
          title: '名称',
          dataIndex: 'name',
          checked: true,
          isNum: false
        },
        {
          title: '分类',
          dataIndex: 'materialType',
          scopedSlots: { customRender: 'materialType' },
          checked: true,
          isNum: false
        },
        {
          title: '适用部门',
          dataIndex: 'materialDepartments',
          width: 300,
          checked: true,
          isNum: false
        },
        {
          title: '适用职位',
          dataIndex: 'materialPositions',
          width: 300,
          checked: true,
          isNum: false
        },
        {
          title: '描述',
          dataIndex: 'description',
          checked: true,
          isNum: false
        },
        {
          title: '操作',
          dataIndex: 'action',
          checked: true,
          isNum: false
        }
      ],
      url: {
        list: '/material/category/list',
        delete: '/material/category/delete',
        deleteBatch: '/material/category/delete',
        copyBatch: '/rule/copy_batch'
      }
    }
  },
  methods: {
    batchDel: function() {
      console.log('JeecgListMixin batchDel')
      if (!this.url.deleteBatch) {
        this.$message.error('请设置url.deleteBatch属性!')
        return
      }
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning('请选择一条记录！')
        return
      } else {
        var ids = this.selectedRowKeys.join(',')
        var that = this

        let url = that.url.deleteBatch
        // 增加作品管理的产品id
        if (this.productionId) {
          url = `${url}/${this.productionId}`
        } else if (this.platformId) {
          url = `${url}/${this.platformId}`
        }
        if (this.selectionRows.some(item => item.hasFile)) {
          this.$confirm({
            title: '确认删除',
            content: this.selectionRows.find(item => item.hasFile).name + '分类存在文件,是否删除选中数据?',
            onOk: function() {
              that.loading = true
              deleteAction(url, { ids: ids })
                .then(res => {
                  if (res.success) {
                    that.$message.success(res.message || res.msg ? '删除成功' : '')
                    if (that.$refs.newTable) {
                      that.$refs.newTable.loadData()
                    } else {
                      that.loadData()
                    }
                    that.onClearSelected()
                    that.$emit('loadData')
                  } else {
                    that.$message.warning(res.message || res.msg)
                  }
                })
                .finally(() => {
                  that.loading = false
                })
            }
          })
        } else {
          this.$confirm({
            title: '确认删除',
            content: '是否删除选中数据?',
            onOk: function() {
              that.loading = true
              deleteAction(url, { ids: ids })
                .then(res => {
                  if (res.success) {
                    that.$message.success(res.message || res.msg ? '删除成功' : '')
                    if (that.$refs.newTable) {
                      that.$refs.newTable.loadData()
                    } else {
                      that.loadData()
                    }
                    that.onClearSelected()
                    that.$emit('loadData')
                  } else {
                    that.$message.warning(res.message || res.msg)
                  }
                })
                .finally(() => {
                  that.loading = false
                })
            }
          })
        }
      }
    },
    setRowkeys(records) {
      this.selectedRowKeys = records.map(item => {
        return item.id
      })
      this.selectionRows = records
    },
    openModal(record) {
      if (!checkPermission('material:edit')) return
      if ( record.index != 7) {
        this.openDetail(record)
      }
    }
  }
}
</script>

<style></style>
